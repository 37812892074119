import posthog from 'posthog-js';
import { goto } from '$app/navigation';
import { writable } from 'svelte/store';
import * as Sentry from '@sentry/svelte';

export const wasConverted = writable(false);

export enum FormPosition {
	Hero = 'hero',
	Footer = 'footer',
	Dialog = 'dialog'
}

export const tryEmailSubmit = (email: string, position: FormPosition) => {
	if (!(window as any).couldLoadAds) {
		return false;
	}
	const url = 'https://a.klaviyo.com/client/subscriptions/?company_id=VYxjSt';
	const options = {
		method: 'POST',
		headers: { revision: '2022-10-17', 'content-type': 'application/json' },
		body: JSON.stringify({
			data: {
				type: 'subscription',
				attributes: { list_id: 'QZdGWK', email }
			}
		})
	};
	posthog.people.set({ email });
	posthog.capture('signup', {
		source: position
	});

	if (window) {
		if (window.dataLayer) {
			window.dataLayer?.push({ event: 'New Subscriber' });
			window.dataLayer?.push({ event: 'sign_up' });
			window.dataLayer?.push({ event: 'purchase' });
		}
	}

	fetch(url, options)
		.then(() => goto('/success'))
		.catch((err) => Sentry.captureException(err));
	wasConverted.set(true);
	return true;
};
